<template>
  <div id="home">
    <Menu />

    <!--<subscribe />-->

    <!--<social />-->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  mounted: function() {
    this.$store.commit("invertedHeader");
    this.$store.commit("resetBGM");
  },
  components: {
    Menu: () => import("@/components/home/Menu"),
    Social: () => import("@/components/home/Social"),
    Subscribe: () => import("@/components/home/Subscribe"),
  },
};
</script>

<style>
@import "../assets/styles/top.css";
</style>
