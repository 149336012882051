import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import News from '../views/News.vue'
import Movies from '../views/Movies.vue'
import BGM from '../views/BGM.vue'
import Live from '../views/Live.vue'
import Gallery from '../views/Gallery.vue'
import Trash from '../views/Trash.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/news',
      name: 'news',
      component: News,
    },
    {
      path: '/movies',
      name: 'movies',
      component: Movies,
    },
    {
      path: '/gallery',
      name: 'gallery',
      component: Gallery,
    },
    {
      path: '/songs',
      name: 'BGM',
      component: BGM,
    },
    {
      path: '/live',
      name: 'live',
      component: Live,
    },
    {
      path: '/trash',
      name: 'trash',
      component: Trash,
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
