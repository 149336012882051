<template>
  <v-container id="about" tag="section">
    <v-row class="pa-3 pb-9 white">
      <v-img
        :src="require('@/assets/top/bg.jpg')"
        height="100%"
        gradient="45deg,rgba(23,39,116,0.8),rgba(119,217,112,0.8),rgba(255,0,117,.8)"
      >
        <v-card tile flat color="rgba(39,39,39,0.0)" dark class="pb-5 pt-8">
          <h1
            class="display-1 text-center mb-0 light-green--text text--accent-3"
          >
            Movies
          </h1>
        </v-card>
        <v-col cols="12">
          <v-row justify="center" class="pt-9 pb-5">
            <v-col cols="12" md="5" v-for="video in videos" :key="i">
              <v-card class="mx-auto mb-5" dark>
                <v-img v-html="video.iframe"></v-img>

                <v-card-title>
                  {{ video.title }}
                </v-card-title>

                <v-card-subtitle>
                  {{ video.subtitle }}
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    color="deep-purple accent-2"
                    dark
                    text
                    :href="video.href"
                    target="blank"
                  >
                    Show in Youtube
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                    v-if="video.text"
                    icon
                    @click="video.show = !video.show"
                  >
                    <v-icon>{{
                      video.show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="video.show">
                    <v-divider></v-divider>

                    <v-card-text
                      style="white-space:pre-wrap; word-wrap:break-word;"
                    >
                      {{ video.text }}
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12" md="5"> </v-col>
          </v-row>
        </v-col>
      </v-img>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About",
  data: function() {
    return {
      videos: [
        {
          iframe:
            '<iframe width="100%" height="315" src="https://www.youtube.com/embed/-USBvNBfDMk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          title: "無観客ライブ",
          show: false,
          text: `Live streaming on Youtube.
We were in front of the Corona disaster.
2019.4.24Youtubeでの生配信ライブです。なんとコロナ禍前でした。`,
          href: "https://www.youtube.com/watch?v=-USBvNBfDMk",
        },
        {
          iframe:
            '<iframe width="100%" height="315" src="https://www.youtube.com/embed/6tZ54PbcZ0E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          title: "ザ・ゴメスノナハゴメスSTATION",
          show: false,
          href: "https://www.youtube.com/watch?v=6tZ54PbcZ0E",
        },
        {
          iframe:
            '<iframe width="100%" height="315" src="https://www.youtube.com/embed/TKzNxD_jDHU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          title: "Past Live. いつかのライブ",
          show: false,
          href: "https://www.youtube.com/watch?v=TKzNxD_jDHU",
        },
        {
          iframe:
            '<iframe width="100%" height="315" src="https://www.youtube.com/embed/lF2yW8dbv90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          title: "Past Live 2. いつかのライブ2",
          show: false,
          href: "https://www.youtube.com/watch?v=lF2yW8dbv90",
        },
        {
          iframe:
            '<iframe width="100%" height="315" src="https://www.youtube.com/embed/JNY-_7hMSmc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          title: "黒パノナハゴメス",
          show: false,
          href: "https://www.youtube.com/watch?v=JNY-_7hMSmc",
          text: `黒パイプという別のバンドがオファーを受けたのですが、主要メンバーが欠席のためゴメスのメンバーがヘルプに入りました。主催者様のご厚意に感謝します。360度カメラで撮った映像ですが元ファイルを所持していないため1アングルのみ。I guess it's boring.`,
        },
        {
          iframe:
            '<iframe width="100%" height="315" src="https://www.youtube.com/embed/n4CO17gmUWw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          title: "ゴ繝｡繧ｹﾉ弱リ繝上ﾒ｡繧ｹ",
          show: false,
          text: `ゴメスノナハゴメス STUDIO LIVE 2021/01
菫ｺ縺ｯｺｯ縲蝗帛香荵晄ｭｳ縺ｮ蝨ｰ蝗ｳ
縲繧ｳ繝�ヵ繧ﾌﾟに壹↓謨｣縺｣ｵ､縺�干縲
蜿｣陬ゅ￠螂ｳ散ｯた赤い縺ｨ蟆丈ｾｿ蟆丞Ι縺ｮ縲
豁｣隲悶↓謗ｻ縺肴ｶ医＆繧後◆謔ｲ魑ｴ縲�
菫ｺ縺ｯ繧ゅ→繧ｵ前は傀儡吶″ｦ繧吶♀蜑
莠ｺ髢薙→繧吶ｂ縺ｯ逧皆殺ｼ�`,
          href: "https://www.youtube.com/watch?v=n4CO17gmUWw",
        },
      ],
    }; //<---関数型に変更
  },
  mounted: function() {
    this.$store.commit("resetHeader");
    this.$store.commit("resetBGM");
  },
};
</script>

<style lang="scss">
.benne {
  font-family: "Benne" !important;
}
</style>
