<template> </template>

<script>
export default {
    name: "BGM",
    mounted: function() {
        this.$store.commit("resetHeader");
        this.$store.commit("invertedBGM");
    },
};
</script>
