<template>
    <v-container id="about" tag="section">
        <v-row class="pa-3 pb-9 white relative">
            <v-col cols="12" md="5" class="">
                <v-img
                    :src="require('@/assets/top/about.jpg')"
                    class="grey lighten-2"
                    height="400"
                    width="100%"
                ></v-img>
            </v-col>
            <v-col cols="12" md="7">
                <!--eng-->
                <transition name="fade">
                    <div v-if="show" class="pr-4 about-caption">
                        <h1 class="display-1 font-weight-light mb-0">
                            Hi! We are
                            <small>GomesunonahaGomesu</small>.
                        </h1>
                        <div class="pl-2 mb-4 text-caption" v-if="show">
                            This pronounce is GOMESU NONAWA GOMESU.
                            ゴメスノナハゴメス is based in Tachikawa, Tokyo.
                            Tachikawa is far west of Shibuya and Roppongi, which
                            you all know. Tachikawa may not be famous, but if
                            you come to shopping, you will find everything!<br />
                            There are many department stores, each with its own
                            depa-chika.*<br />
                            <small
                                >*Japanese delicatessen section in a department
                                store basement</small
                            ><br />
                            There is a famous movie theater in Japan. This is a
                            crazy movie theater that has been completely
                            replaced with insanely high speakers just because
                            you want to show 'Mad Max: Fury Road' at the highest
                            volume.<br />
                            And there is also the largest national park in
                            Japan. The fireworks in here is very famous, and
                            despite its large size, it is crowded like a bar on
                            the weekends.<br />
                            There are many high-level restaurants such as
                            French, Spanish, Italian, hamburger, Thai, Chinese,
                            Japanese izakaya, ramen, etc.. so there is no
                            problem. (I will guide you next time)<br />
                            In addition, Tachikawa is the setting for the
                            popular Japanese manga "Hakaiju", which is messed up
                            by monsters. There are so many people that I die a
                            lot. Sounds fun.
                        </div>
                    </div>
                </transition>
                <!--jpn-->
                <transition name="fade">
                    <div v-if="!show" class="pr-4 about-caption">
                        <h1 class="font-weight-light mb-0">
                            こんにちは! わたしたちは ゴメスノナハゴメスです.
                        </h1>
                        <div class="pl-2 mb-4 text-caption">
                            ゴメスノナハゴメスは５人組バンドです。
                        </div>
                    </div>
                </transition>

                <v-btn
                    color="black"
                    class="lang-btn"
                    dark
                    depressed
                    @click="language"
                    v-if="show"
                >
                    IN JAPANESE
                </v-btn>
                <v-btn
                    color="black"
                    class="lang-btn"
                    dark
                    depressed
                    @click="language"
                    v-else
                >
                    IN ENGLISH
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "About",
    data: function() {
        return {
            show: true,
            text: "IN JAPANESE",
        }; //<---関数型に変更
    },
    mounted: function() {
        this.$store.commit("resetHeader");
        this.$store.commit("resetBGM");
    },
    methods: {
        language: function() {
            this.show = !this.show;
        },
    },
};
</script>

<style lang="scss">
.relative {
    position: relative;
}
.lang-btn {
    position: absolute;
    bottom: 15px;
    right: 15px;
}
</style>
