import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    articles: require("@/data/articles.json"),
    drawer: false,
    header: false,
    bgmPage: false,
    nowPlaying: false,
    items: [
      {
        text: "Home",
        to: "/",
      },
      /*
      {
        text: 'About',
        to: '/about',
      },
      {
        text: 'News',
        to: '/news',
      },
      {
        text: "Gallery",
        to: "/gallery",
      },
      */
      {
        text: "Movies",
        to: "/movies",
      },
      {
        text: "SONGS",
        to: "/songs",
      },
      /*
      {
        text: "LIVE",
        to: "/live",
      },
      */
      {
        text: "TRASHCAN",
        to: "/trash",
      },
    ],
  },
  getters: {
    categories: (state) => {
      const categories = [];

      for (const article of state.articles) {
        if (
          !article.category ||
          categories.find((category) => category.text === article.category)
        )
          continue;

        const text = article.category;

        categories.push({
          text,
          href: "#!",
        });
      }

      return categories.sort().slice(0, 4);
    },
    links: (state, getters) => {
      //      return state.items.concat(getters.categories)
      return state.items;
    },
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: (state) => (state.drawer = !state.drawer),
    resetHeader: function(state) {
      state.header = false;
    },
    invertedHeader: function(state) {
      state.header = true;
    },
    resetBGM: function(state) {
      state.bgmPage = false;
    },
    invertedBGM: function(state) {
      state.bgmPage = true;
    },
    setNowPlaying: function(state) {
      state.nowPlaying = true;
    },
    ejectNowPlaying: function(state) {
      state.nowPlaying = false;
    },
  },
  actions: {},
});
